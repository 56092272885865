import React, { useState, useCallback } from "react"
import { useDispatch, useSelector } from 'react-redux'
import {Container, Row, Col, Modal, Button } from "react-bootstrap"
import * as invoicesActions from '../store/actions/invoice'
import * as estimatesActions from '../store/actions/estimate'
import * as profilesActions from '../store/actions/profile'
import * as expensesActions from '../store/actions/balance'
import { Link } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import './NavigateAccount.css'




const NavigateAccount = () => {

    const [show, setShow] = useState(false);

    const dispatch = useDispatch();
   

   

    const searchByPhone = useSelector(state => state.invoices.invoices)
    
    const [inputfiltro, setInputFiltro] = useState('')
    const [filterPhone, setFilterPhone] = useState(null)

    const  onChangeHandlerPhone = (event) => {
      setInputFiltro(event.target.value);   
     }
     const handlerSubmitclient = () => {
      setFilterPhone(searchByPhone.filter(item => item.Phone === inputfiltro))        
     }

     function oncClosemodal() {
      setFilterPhone(null)
       setShow(false)
     }

    


    const loadInvoices = useCallback( async () => {
            dispatch(invoicesActions.fetchInvoices())
        },[dispatch]);

    const loadEstimates = useCallback( async () => {
        dispatch(estimatesActions.fetchEstimates())
    },[dispatch]);

   const loadProfiles = useCallback( async () => {
         dispatch(profilesActions.fetchProfiles())  
      },[dispatch]);

    const handlerSubmit = () => {
        loadInvoices()        
    }

    const handlerSubmitestimate = () => {
      loadEstimates()        
    }
    
    const profilesHandler = () => {
      loadProfiles()        
    }

       const ourClientHandler = () => {
        dispatch(invoicesActions.fetchInvoices())
        setShow(true)
         }

    const expensesHandler = () => {
      dispatch(expensesActions.fetchExpenses())
    }
         

    return (
        <Container>
            <Row>
                <Col md={3}>     
                              
                    <div className="d-grid gap-2 mb-3">
                       <Button style={{width: '100%'}} variant="outline-info" size="lg" onClick={ourClientHandler}>
                         Our Clients
                       </Button>
                       <Modal
                         show={show}
                         onHide={() => setShow(false)}
                         aria-labelledby="contained-modal-title-vcenter"
                         centered
                         backdrop="static"
                         keyboard={false}
                         >
                         <Modal.Header closeButton>
                           <Modal.Title id="example-custom-modal-styling-title">
                             Search
                           </Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                           <Row>
                             <Col>
                                  <Autocomplete                      
                                      size='small'
                                      id="idPhone"
                                      options={ searchByPhone}
                                      getOptionLabel={(option) => option.Phone}
                                      style={{ width: 180 }}
                                      renderInput={(params) => <TextField {...params} id="Phone" name="Phone" type='text' label="--Phone Number-- "  variant="standard"  onSelect={onChangeHandlerPhone } />}
                                    /> 
                             </Col>
                             <Col>
                                    <Button style={{width: '50%'}} variant="outline-info" onClick={handlerSubmitclient} >
                                       Search
                                    </Button>
                             </Col>                                     
                            </Row>
                            {   filterPhone != null &&
                              <Row className="mt-3">
                                  <Col>
                                      {
                                        filterPhone.map(x => (
                                          <div key={x.id} className="mb-2" >
                                            <Link to={{
                                                       pathname: "/account/ourclientinvoice",
                                                       state: { id: x.id },
                                                     }} >
                                               <Button  style={{width: '50%'}} variant="outline-info" >
                                                  {x.Make} {x.Model}
                                               </Button>
                                            </Link>
                                          </div>
                                        ))
                                      }
                                  </Col>  
                              </Row>
                            }
                         </Modal.Body>
                         <Modal.Footer>
                          <Button onClick={oncClosemodal} variant="outline-info">Close</Button>
                        </Modal.Footer>
                       </Modal>
                    </div>
                    <div className="d-grid gap-2 mb-3">
                         <Link to='/account/gottawebdesign' >
                          <Button style={{width: '100%'}} variant="outline-info" size="lg">
                             Make a Payment
                          </Button>
                      </Link>
                      </div>  
                </Col>                
                <Col md={3}>
                      <div className="d-grid gap-2 mb-3">
                      <Link to='/account/invoice' >
                          <Button style={{width: '100%'}} variant="outline-info" size="lg">
                             Create Invoice
                          </Button>
                      </Link>
                      </div>
                      <div className="d-grid gap-2  mb-3">
                      <Link to='/account/estimate' >
                          <Button style={{width: '100%'}} variant="outline-info" size="lg">
                             Create Estimate
                          </Button>
                      </Link>
                      </div>
                      <div className="d-grid gap-2  mb-3">
                      <Link to='/account/inspection' >
                          <Button style={{width: '100%'}} variant="outline-info" size="lg">
                            General Inspection
                          </Button>
                      </Link>
                      </div>
                </Col>
                <Col md={3}>
                      <div className="d-grid gap-2 mb-3">
                      <Link to='/account/searchinvoice'  onClick={handlerSubmit} >
                          <Button style={{width: '100%'}} variant="outline-info" size="lg">
                            Search Invoice
                          </Button>
                      </Link>
                      </div>
                      <div className="d-grid gap-2 mb-3">
                      <Link to='/account/searchestimate'  onClick={handlerSubmitestimate} >
                          <Button style={{width: '100%'}} variant="outline-info" size="lg">
                            Search Estimate
                          </Button>
                      </Link>
                      </div>
                     
                </Col>
                <Col md={3}>
                      <div className="d-grid gap-2 mb-3">
                        <Link to='/account/profiles'  onClick={profilesHandler} >
                            <Button style={{width: '100%'}} variant="outline-info" size="lg">
                              Human Resourses
                            </Button>
                        </Link>
                      </div> 
                      <div className="d-grid gap-2 mb-3">
                        <Link to='/account/balance' >
                            <Button style={{width: '100%'}} variant="outline-info" size="lg" onClick={expensesHandler}>
                              Balance
                            </Button>
                        </Link>
                      </div>                     
                </Col>
            </Row>             
            </Container>         
    )
}

export default NavigateAccount

