import React from 'react'
import './HeaderBlock.css'

function HeaderBlock() {
  return (    
    <header>
      <div className="overlay"></div>

      <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
        <source src="https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4" type="video/mp4" />
      </video>
      <div className="container h-100">
        <div className="d-flex h-100 text-center align-items-center">
          <div className="w-100 text-white">
            <h1 className="display-3">You imagine impossible.</h1>
            <h1 className="display-3">We build impossible.</h1>
            <p className="lead mb-0">We Care About Your Business</p>
          </div>
        </div>
      </div>
    </header>  
  
  
  
  )
}

export default HeaderBlock

