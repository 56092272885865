class Expense {
    constructor(id, category, description, register, payment, amount, months, total, date) {
      this.id = id;
      this.category = category;
      this.description = description;
      this.register = register;
      this.payment = payment;
      this.amount = amount;
      this.months = months;
      this.total = total;
      this.date = date;
    }
  }
  
  export default Expense;
  